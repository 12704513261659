<template>
  <h2>
    <p>{{ title }}<img src="../../../assets/shadow.png" alt /></p>
  </h2>
</template>

<script>
export default {
  props: ['title'],
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 28px;
  font-weight: 400;
  padding: 30px 0;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  p {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    > img {
      position: absolute;
      z-index: -1;
      height: 13px;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
