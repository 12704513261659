import Vue from "vue"
import App from "./App.vue"
import router from "./router"

import uTitle from '@/components/ui/title'
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

Vue.component('u-title', uTitle)
Vue.config.productionTip = false

// Vue.prototype.$customerServiceLink = new Date().getHours() > 8 ? 'https://www26c1.53kf.com/webCompany.php?kf_sign=DI1ODMTU5OczMjExODg3NTY0OTU2MDEyNzIyMDgxNTU=&arg=10208155&style=1&kflist=off&kf=2772871435%40qq.com%2C1901399166%40qq.com%2C2713198802%40qq.com%2C1852303966%40qq.com%2C641302463%40qq.com%2C1815613782%40qq.com%2C1120465273%40qq.com%2C2102826435%40qq.com%2C2817700763%40qq.com%2C2563613419%40qq.com%2C2687033397%40qq.com%2C1669964656%40qq.com&zdkf_type=1&lnk_overflow=0&language=zh-cn&charset=GBK&username=&userinfo=&introurl=&lyurl=&lytype=0&copartner=&referer=http%3A%2F%2Fmashibing.com%2F&keyword=&brief=&logo=&question=&uid=2d242da47aadaebb8576fe6bc8e5fddc&guest_id=&tfrom=2&timeStamp=1598258738835&ucust_id=' : 'http://im172.easyliao.com/live/chat.do?c=13599&g=29546&config=46013&tag=0'
Vue.prototype.$customerServiceLink = 'https://www26c1.53kf.com/webCompany.php?kf_sign=DI1ODMTU5OczMjExODg3NTY0OTU2MDEyNzIyMDgxNTU=&arg=10208155&style=1&kflist=off&kf=2772871435%40qq.com%2C1901399166%40qq.com%2C2713198802%40qq.com%2C1852303966%40qq.com%2C641302463%40qq.com%2C1815613782%40qq.com%2C1120465273%40qq.com%2C2102826435%40qq.com%2C2817700763%40qq.com%2C2563613419%40qq.com%2C2687033397%40qq.com%2C1669964656%40qq.com&zdkf_type=1&lnk_overflow=0&language=zh-cn&charset=GBK&username=&userinfo=&introurl=&lyurl=&lytype=0&copartner=&referer=http%3A%2F%2Fmashibing.com%2F&keyword=&brief=&logo=&question=&uid=2d242da47aadaebb8576fe6bc8e5fddc&guest_id=&tfrom=2&timeStamp=1598258738835&ucust_id='

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event("render-event"))
  },
})
