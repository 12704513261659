<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
    // window.addEventListener('resize', () => {
    //   this.act = false
    //   this.$nextTick(() => {
    //     this.act = true
    //   })
    // })
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  // width: 100vw;
  // overflow-x: hidden;
}
.card {
  border: none;
}
.bgfff {
  background-color: #fff;
}
.more-btn {
  display: inline-block;
  padding: 0 25px;
  border: 1px solid #b63388;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  font-size: 18px;
  box-sizing: border-box;
  color: #b63388;
  text-align: center;
  cursor: pointer;
  &:focus,
  &:hover {
    text-decoration: none;
    color: #b63388;
    outline: none;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.multi-ellipsis--l2 {
  -webkit-line-clamp: 2;
}
.multi-ellipsis--l2,
.multi-ellipsis--l3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.multi-ellipsis--l3 {
  -webkit-line-clamp: 3;
}

a {
  color: inherit;
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
</style>
